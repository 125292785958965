
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
@import './AutoPaymentForm/style';
@import './TrapModal/style';
@import './OrderModal/style';
@import './AutopayModal/style';
@import './TariffsModal/style';

.popup {
  @include z-index(
    fixed,
    blind
  ); // TODO: Временное решение, пока не переедем на ui-modal

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  justify-content: center;
  overflow-y: auto;
  background-color: rgba(var(--accent-always-black-color-rgb), .75);

  &.js-popup--active {
    display: flex;
  }

  &:focus-visible {
    outline: none;
  }
}

.is-safari .popup:focus {
  outline: none;
}

.popup__wrapper {
  position: relative;
  width: 100%;
  margin: auto 0;
  padding: 40px 20px;
  border-radius: 10px;
  background-color: var(--bg-with-shadow-color);

  @include media(md) {
    width: 532px;
    padding: 32px 80px 32px 32px;
    border-radius: 20px;
  }

  @include media(lg) {
    width: 540px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
  }
}

.popup__close {
  @include z-index(absolute, popup, close-button);

  top: 20px;
  right: 20px;

  @include media(md) {
    top: 24px;
    right: 24px;
  }
}

.popup__title {
  margin-bottom: 20px;

  @include media(sm-max) {
    padding-right: 50px;
  }
}

.popup__desc {
  margin-bottom: 32px;
}

// Trap popup
.trap-popup {
  @include media(md) {
    display: grid;
    grid-column-gap: 48px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr 200px;
    width: 696px;
    padding-right: 64px;
  }

  @include media(lg) {
    grid-template-columns: 1fr 248px;
    width: 800px;
    padding-right: 60px;
  }
}

.trap-popup__pic-wrapper {
  display: block;
  width: 157px;
  height: 158px;

  @include media(sm-max) {
    margin: 0 auto 44px;
  }

  @include media(md) {
    grid-row: 1/-1;
    grid-column: 2/-1;
    align-self: center;
    width: 200px;
    height: 232px;
  }

  @include media(lg) {
    width: 248px;
    height: 287px;
  }
}

.trap-popup__pic {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.trap-popup__title {
  margin-bottom: 16px;

  @include media(lg) {
    width: 390px;
    margin-bottom: 20px;
  }

  span {
    @include z-index(popup, raised);

    display: inline-block;
    color: var(--accent-always-black-color);

    &::before {
      @include z-index(below);

      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: calc(100% + 8px);
      height: 28px;
      border-radius: 20px;
      background-color: var(--accent-sale-color);
      transform: translate(-50%, -50%);
      content: '';

      @include media(lg) {
        width: calc(100% + 12px);
        height: 40px;
      }
    }
  }
}

.trap-popup__desc {
  margin-bottom: 20px;

  @include media(md) {
    margin-bottom: 28px;
  }
}

// SMART trap popup
.smart-trap-popup__pic-wrapper {
  @include media(sm-max) {
    margin-right: auto;
    margin-left: auto;
  }
}
