@import 'node_modules/@skillbox/front-box/dist/Functional/shared/UiRadioButton/style.scss';

.auto-payment-form {
  @include media(sm-max) {
    padding-bottom: 70px;
  }
}

.auto-payment-form__wrapper {
  padding: 40px 20px 0;

  @include media(md) {
    padding: 32px 20px;
  }

  @include media(lg) {
    padding: 40px 20px;
  }
}

.auto-payment-form__header {
  margin-bottom: 24px;

  @include media(md) {
    width: 368px;
    margin-right: auto;
    margin-left: auto;
  }

  @include media(lg) {
    width: 468px;
    margin-bottom: 32px;
  }
}

.auto-payment-form__title {
  margin-bottom: 24px;

  @include media(sm-max) {
    padding-right: 40px;
  }

  @include media(md) {
    text-align: center;
  }

  @include media(lg) {
    margin-bottom: 32px;
  }
}

.auto-payment-form__desc {
  margin-bottom: 0;

  @include media(md) {
    text-align: center;
  }
}

.auto-payment-form__form {
  @include media(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.auto-payment-form__types {
  margin-bottom: 40px;

  @include media(md) {
    margin-bottom: 32px;
  }
}

.auto-payment-form__type {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.auto-payment-form__faq {
  @include media(sm-max) {
    margin-bottom: 40px;
  }

  @include media(md) {
    order: 1;
    width: 536px;
  }

  @include media(lg) {
    width: 768px;
  }
}

.auto-payment-form__footer {
  @include media(sm-max) {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px 20px 24px;
    border-radius: 20px 20px 0 0;
    background-color: var(--bg-with-shadow-color);
  }

  @include media(md) {
    margin-bottom: 40px;
  }

  @include media(lg) {
    margin-bottom: 56px;
  }
}

.auto-payment-form__types,
.auto-payment-form__footer {
  @include media(md) {
    width: 368px;
  }

  @include media(lg) {
    width: 468px;
  }
}

.auto-payment-form.is-scroll .auto-payment-form__footer {
  @include media(sm-max) {
    @include ui-shadow-4pt;
  }
}

.auto-payment-form__button {
  padding-top: 9px;
  padding-bottom: 9px;

  .ui-button__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.is-safari .auto-payment-form__policy a:focus {
  text-decoration: none;
}

// stylelint-disable
.auto-payment-form__policy {
  margin-top: 16px;
  margin-bottom: 0;
  color: var(--text-secondary-color);

  a {
    color: inherit;
    text-decoration: underline;

    @include hover {
      text-decoration: none;
    }

    &:focus-visible {
      text-decoration: none;
    }
  }
}
// stylelint-enable

.extended-autopayment-method {
  position: relative;
  display: flex;
}

.extended-autopayment-method__label {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.extended-autopayment-method__box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;

  @include media(lg) {
    padding: 20px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--stroke-main-color);
    border-radius: 20px;
    transition: border-color ease-in-out 150ms;
    content: '';

    @include media(lg) {
      border-radius: 24px;
    }
  }
}

.extended-autopayment-method__header {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 20px 1fr auto;
  align-items: flex-start;

  &::before {
    @include ui-radio-button;
    @include ui-radio-button-small;

    @include media(lg) {
      margin-top: 2px;
    }
  }
}

.extended-autopayment-method__title {
  display: block;
  margin-right: 8px;

  @include media(lg) {
    margin-right: 10px;
  }
}

.extended-autopayment-method__details {
  display: block;
  overflow: hidden;
  transition: height ease-in-out 400ms;
}

.extended-autopayment-method__desc {
  display: block;
  margin-top: 16px;
  color: var(--text-secondary-color);
}

.extended-autopayment-method__list {
  display: block;
  margin-top: 24px;

  @include media(lg) {
    margin-top: 28px;
  }
}

.extended-autopayment-method__item {
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: 1fr auto;

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  &--total {
    margin-top: 12px;
    font-weight: 500;
  }
}

.extended-autopayment-method__item-text {
  display: grid;
  grid-column: 1/2;
  grid-column-gap: 4px;
  grid-template-columns: auto 1fr;

  &::after {
    display: block;
    align-self: flex-end;
    margin-bottom: 4px;
    margin-left: 4px;
    border-bottom: 1px dashed var(--stroke-main-color);
    content: '';

    @include media(lg) {
      margin-bottom: 5px;
    }
  }
}

.extended-autopayment-method__item-price {
  display: block;
  grid-column: 2/-1;
}

// stylelint-disable
.extended-autopayment-method__images {
  display: flex;
  align-items: center;
  margin-top: 24px;

  @include media(lg) {
    margin-top: 28px;
  }

  img {
    height: 16px;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}
// stylelint-enable

.extended-autopayment-method__info {
  position: absolute;
  right: 13px;
  bottom: 13px;
  display: flex;
  color: var(--icon-addition-color);

  @include media(lg) {
    right: 17px;
    bottom: 17px;
  }

  &:focus-visible {
    color: var(--icon-main-color);
  }

  @include hover {
    color: var(--icon-main-color);
  }
}

.is-safari .extended-autopayment-method__info:focus {
  color: var(--icon-main-color);
}

.extended-autopayment-method__input:checked
  ~ .extended-autopayment-method__box::before {
  border-color: var(--accent-brand-color);
  border-width: 2px;
}

.extended-autopayment-method__input:checked
  ~ .extended-autopayment-method__box
  .extended-autopayment-method__header::before {
  @include ui-radio-button-checked;
  @include ui-radio-button-checked-small;
}

@include hover('.extended-autopayment-method') {
  .extended-autopayment-method__box::before {
    border-color: var(--accent-brand-color);
  }

  .extended-autopayment-method__header::before {
    @include ui-radio-button-hover;
  }
}

.extended-autopayment-method__input:focus-visible
  ~ .extended-autopayment-method__box::before {
  border-color: var(--accent-brand-color);
}

.is-safari
  .extended-autopayment-method__input:focus
  ~ .extended-autopayment-method__box::before {
  border-color: var(--accent-brand-color);
}
