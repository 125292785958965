.trap-modal {
  @include media(md) {
    width: 696px;
  }

  @include media(lg) {
    width: 800px;
  }
}

.trap-modal__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px 20px;

  @include media(md) {
    display: grid;
    grid-column-gap: 48px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr 200px;
    padding: 32px 64px 32px 32px;
    border-radius: 20px;
  }

  @include media(lg) {
    grid-template-columns: 1fr 248px;
    padding: 40px 60px 40px 40px;
  }
}

.trap-modal__pic-wrapper {
  display: block;
  grid-row: 1/2;
  width: 157px;
  height: 158px;

  @include media(sm-max) {
    margin: 0 auto 44px;
  }

  @include media(md) {
    grid-row: 1/-1;
    grid-column: 2/-1;
    align-self: center;
    width: 200px;
    height: 232px;
  }

  @include media(lg) {
    width: 248px;
    height: 287px;
  }
}

.trap-modal__header {
  margin-bottom: 32px;
}

.trap-modal__title {
  span {
    @include z-index(popup, raised);

    display: inline-block;
    color: var(--accent-always-black-color);

    &::before {
      @include z-index(below);

      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: calc(100% + 8px);
      height: 28px;
      border-radius: 20px;
      background-color: var(--accent-sale-color);
      transform: translate(-50%, -50%);
      content: '';

      @include media(lg) {
        width: calc(100% + 12px);
        height: 40px;
      }
    }
  }
}

.trap-modal__desc {
  margin-bottom: 0;
  color: var(--text-addition-color);
}

// black friday
.trap-modal__wrapper.trap-modal__wrapper--black,
.trap-modal__wrapper.trap-modal__wrapper--sng-ny,
.trap-modal__wrapper.trap-modal__wrapper--ny,
.trap-modal__wrapper.trap-modal__wrapper--ny-january,
.trap-modal__wrapper.trap-modal__wrapper--sng-ny-january {
  color: var(--text-always-white-color);
  background-color: var(--text-always-black-color);

  @include media(md) {
    padding: 48px;
    grid-column-gap: 40px;
    grid-template-columns: 1fr 220px;
  }

  @include media(lg) {
    grid-column-gap: 64px;
  }

  .trap-modal__header {
    @include media(md) {
      margin-bottom: 48px;
    }
  }

  .trap-modal__title span {
    margin-right: 10px;

    &::before {
      width: calc(100% + 18px);
      height: 36px;
      background-color: var(--bg-always-white-color);
      border-radius: 26px;

      @include media(lg) {
        width: calc(100% + 20px);
        height: 50px;
      }
    }
  }

  .form__policy {
    color: var(--text-addition-color);
  }

  .trap-modal__pic-wrapper {
    width: 252px;
    height: 84px;

    @include media(sm-max) {
      margin: -40px 0 32px -20px;
    }

    @include media(md) {
      width: 268px;
      height: 460px;
      margin-top: auto;
      margin-bottom: -48px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
    }
  }
}

// new year sng
.trap-modal__wrapper.trap-modal__wrapper--sng-ny {
  background-color: #100f18;

  @include media(lg) {
    grid-column-gap: 60px;
  }

  .trap-modal__title {
    margin-right: -10px;

    span {
      margin-left: 10px;

      &::before {
        background-color: #fba141;
      }
    }
  }

  .trap-modal__pic-wrapper {
    @include media(md) {
      width: 268px;
      height: 500px;
    }
  }
}

.trap-modal__wrapper.trap-modal__wrapper--ny {
  color: var(--text-main-color);
  background-color: #ace5c5;

  .trap-modal__desc {
    color: var(--text-secondary-color);
  }

  .form__policy {
    color: var(--text-secondary-color);
  }
}

.trap-modal__wrapper.trap-modal__wrapper--ny-january {
  background-color: #5a00b8;

  .ui-button:not(:hover, :focus-visible) {
    background-color: #333333;
  }

  .trap-modal__desc {
    color: var(--text-always-white-color);
  }

  .form__policy,
  .form__accepted {
    color: rgba(#fff, .7);
  }

  .trap-modal__pic-wrapper {
    @include media(md) {
      width: 268px;
      height: 500px;
    }
  }
}

.trap-modal__wrapper.trap-modal__wrapper--sng-ny-january {
  color: var(--text-always-white-color);
  background-color: #1e1e1e;

  .trap-modal__title {
    margin-right: -10px;

    @include media(0, 480) {
      font-size: 26px;
    }

    span {
      color: var(--text-always-white-color);
      margin-left: 10px;

      &::before {
        background-color: #8f5aff;
      }
    }
  }

  .trap-modal__desc {
    color: var(--text-always-white-color);

    br {
      @include media(0, 480) {
        display: none;
      }
    }
  }

  .trap-modal__pic-wrapper {
    @include media(md) {
      width: 268px;
      height: 500px;
    }
  }

  .form__policy,
  .form__accepted {
    color: #666;
  }
}
