.tariffs-v4-modal {
  @include media(md) {
    width: 696px;
  }

  @include media(lg) {
    width: 928px;
  }

  @include media(xl) {
    width: 1080px;
  }
}

.tariffs-v4-modal__wrapper {
  overflow: hidden;
  padding: 40px 20px;

  .tariffs-v4__item {
    border: 1px solid var(--stroke-accent-color-invert);
  }
}
