.autopayment-modal {
  @include media(md) {
    width: 696px;
  }

  @include media(lg) {
    width: 928px;
  }

  @include media(xl) {
    width: 1080px;
  }
}
