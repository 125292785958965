.order-modal {
  @include media(md) {
    width: 532px;
  }

  @include media(lg) {
    width: 540px;
  }
}

.order-modal .ui-modal__scroll {
  @include media(lg) {
    padding-right: 32px;
    padding-left: 32px;
  }
}

.order-modal__header {
  margin-bottom: 32px;

  @include media(md) {
    max-width: 412px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

.order-modal__title {
  @include media(sm-max) {
    padding-right: 40px;
  }
}

.order-modal__desc {
  margin-bottom: 0;
  margin-top: 16px;
}
